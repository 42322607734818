<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Virtual Bibs and Finisher Certificate for {{event.name}}</v-card-title>

      <v-card-text>      
        <p>
          Use this page to enable and preview virtual bibs and finisher certificate for {{event.name}}. 
        </p>

        <h3 class="subtitle">Virtual Bibs</h3>
        <p>
          Virtual Bibs are generated and emailed to participants when they join the event. This is a great way to generate shareable content even before the event has officially started.
        </p>
        <v-alert v-if="event.bib_doc_id" type="success" prominent>
          Virtual Bibs are available for this event.
        </v-alert>
        <v-alert v-if="!event.bib_doc_id" type="info" prominent>
          Virtual Bibs are available as an additional add-on to the platform. Each bib needs to be designed and prepared for each specific event.
          An additional fee is charged to do this. Please <router-link to="/contact">contact us</router-link> to request virtual bibs for your event.
        </v-alert>

        <p v-if="event.bib_doc_id">
          <v-switch 
            v-model="event.generate_bib" 
            label="Automatically generate bib when joining this event"
            @change="enableBibs"
            hide-details
            />
          <v-switch 
            v-model="event.send_bib_email" 
            label="Automatically email bib to participant when it's ready to view"
            @change="enableBibs"
            hide-details
            />
            <router-link :to="{name:'eventmanagerEdit', params: {id: event.id}}">Customize Email Text</router-link>
        </p>
        <p v-if="event.bib_doc_id">
          <v-btn outlined color="primary" class="" @click="generatePreviewBib()">
            Preview Bib
          </v-btn>
        </p>

        <h3 class="subtitle">Finish Certificates</h3>
        <p>
          When a certificate is prepared it will be send as email to every finisher and will also be available to share and download from the results pages.
        </p>

        <v-alert v-if="!races.some(x => x.certificate_doc_id)" type="info" prominent>
          Finisher Certificates are available as an additional add-on to the platform. Each certificate needs to be designed and prepared for each specific event.
          An additional fee is charged to do this. Please <router-link to="/contact">contact us</router-link> to request certificates for your event.
        </v-alert>
        <v-data-table
          :headers="raceHeaders"
          :items="races"
          sort-by="name"
          :items-per-page="25"
          hide-default-footer
        >
          <template v-slot:item.status="{ item }">
            <span v-if="item.certificate_doc_id">
              <v-icon small color="green">fa fa-check-circle</v-icon> Available
            </span>
            <span v-else>Not Configured</span>
          </template>
          <template v-slot:item.email="{ item }">
            <span v-if="item.certificate_doc_id">
              <span v-if="item.send_certificate_email"><v-icon small color="green">fa fa-check-circle</v-icon> Yes</span>
              <span v-else><v-icon small color="red">fa fa-times-circle</v-icon> No</span>
            </span>
          </template>
          <template v-slot:item.generate="{ item }">
            <span v-if="item.certificate_doc_id">
              <v-switch 
                v-model="item.certificate" 
                label="Auto generate"
                @change="enableCertificates(item.id)"
                hide-details
                dense
                class="ma-0 pa-0"
                />
            </span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn v-if="item.certificate_doc_id" small color="primary" text class="" @click="generatePreview(item.id)">
              Preview
            </v-btn>
          </template>
          <template v-slot:no-data>
            <i>No races created, add at least one race to this event.</i>
          </template>
        </v-data-table>

      </v-card-text>

      <v-dialog v-if="false" scrollable fullscreen v-model="editorVisible">
        <template v-slot:activator="{ on }">
          <v-btn small text block color="primary" v-on="on">
            <v-icon class="mr-2">fadl fa fa-pencil</v-icon> Open Certificate Editor
          </v-btn>
        </template>
        <v-card color="black">
          <v-toolbar dark color="grey darken-4">
            <v-btn icon dark class="ml-2" @click="editorVisible = false">
              <v-icon>fa-times-circle</v-icon>
            </v-btn>
            <v-toolbar-title>Certificate Editor</v-toolbar-title>
            <v-spacer/>
            <v-btn text dark class="ml-2" @click="placeholderVisible = !placeholderVisible">
              <v-icon class="mr-2">fa-info-circle</v-icon> Tips
            </v-btn>
          </v-toolbar>
          <div v-if="placeholderVisible" style="position: absolute; top:64px; right:0; bottom:0; padding:0px; z-index:999; width: 400px; background-color:black; color:white;" 
            >
            <p class="px-3 pt-3">Use any of these names inside your designs to add dynamic content.</p>
            <p class="px-3"><strong>Placeholders</strong></p>
            <v-data-table
              :headers="headers"
              :items="placeholders"
              :items-per-page="100"
              hide-default-footer
              dark
              dense
              />
            <p class="px-3 pt-3">All units (metric/imperial) are according to event settings.</p>
            <!-- <p class="px-3"><a href="" target="_blank">More tips</a></p> -->
          </div>
          <!--
          <div     
            style="top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:998; background-color:yellow; color:white;" 
            >
            editor here
          </div>
          -->
          <iframe     
            style="top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:998;" 
            allowfullscreen 
            loading="lazy" 
            frameborder="0"    
            :src="editorEmbedUrl" >
          </iframe>
        </v-card>
      </v-dialog>
      <v-dialog v-model="previewAssetDialog" width="600">
        <v-card>
          <v-progress-circular v-if="!previewAssetData" indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
          <img v-if="previewAssetData" :src="previewAssetData" style="max-width:100%;" />
          <v-card-actions>
            <v-btn text @click="previewAssetDialog=false">Close</v-btn>
            <v-spacer/>
            <span v-if="previewAssetData" class="text-muted">Right click the image and select 'Save Image As...' to save this image.</span>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import Header from './_Header.vue'

export default {
  name: "Certificate",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      event: null,
      siteData: siteData,
      editorVisible: false,
      placeholderVisible: false,
      previewAssetDialog: null,
      previewAssetData: null,
      races: [],
      raceHeaders: [
        { text: 'Name', sortable: true, value: 'name',},
        { text: 'Status', sortable: false, value: 'status',},
        { text: 'Auto-generate (on completion)', sortable: false, value: 'generate',},
        { text: 'Send email', sortable: false, value: 'email',},
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headers: [
        { text: 'Code', value: 'id', sortable: false },
        { text: 'Value', value: 'text', sortable: false },
        { text: 'Example', value: 'example', sortable: false},
      ],
      placeholders: [
        { id: 'given_name', text: 'Given Name', example: 'Jane' },
        { id: 'family_name', text: 'Family Name', example: 'Doe' },
        { id: 'name', text: 'Full Name', example: 'Jane Doe' },
        { id: 'bib', text: 'Bib', example: '42' },
        { id: 'category', text: 'Category', example: 'F30-35' },
        { id: 'gender', text: 'Gender', example: 'F' },
        { id: 'race', text: 'Race name', example: '5K' },
        { id: 'event', text: 'Event name', example: 'Big Challenge' },
        { id: 'distance', text: 'Distance', example: '123.45' },
        { id: 'time', text: 'Time', example: '18:23' },
        //{ id: 'elevation_gain', text: '', example: '' },
        { id: 'speed', text: 'Speed', example: '6.54' },
        { id: 'pace', text: 'Pace', example: '06:23' },
        { id: 'rank_overall', text: 'Rank (overall)', example: '12' },
        { id: 'rank_gender', text: 'Rank (gender)', example: '7' },
        { id: 'rank_category', text: 'Rank (category)', example: '3' },
        { id: 'team', text: 'Team name', example: 'Distance Getters' },
        { id: 'active_days', text: 'Active Days (in challenge)', example: '19' },
        { id: 'completion_date', text: 'Date of completion', example: '06/30/2021' },
      ]
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id, raceId) {
      this.event = (await eventManagerService.get(id)).data;
      this.races = this.event.races || [];
      //this.race = this.event.races.find(r => r.id == raceId);
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id, this.$route.params.raceId);
      }
    },

    async generatePreviewBib() {
      this.previewAssetData = null;
      this.previewAssetDialog = true;
      var response = await eventManagerService.getBibPreview(this.event.id);
      let reader = new FileReader();
      reader.readAsDataURL(response.data); 
      reader.onload = () => {
          this.previewAssetData = reader.result;
      };
    },

    async generatePreview(raceId) {
      this.previewAssetData = null;
      this.previewAssetDialog = true;
      var response = await eventManagerService.getCertificatePreview(this.event.id, raceId);
      let reader = new FileReader();
      reader.readAsDataURL(response.data); 
      reader.onload = () => {
          this.previewAssetData = reader.result;
      };
    },

    async enableBibs() {
      await eventManagerService.put(this.event);
      this.$helpers.toastResponse(this, {status: 'OK'}, `Saved! Bibs are now ${this.event.generate_bib ? 'ON' : 'OFF'}.`);
    },
    async enableCertificates(raceId) {
      //this.race.certificate = false;
      //this.race.certificate_doc_id = 'gfyseo8wa';
      await eventManagerService.put(this.event);
      this.$helpers.toastResponse(this, {status: 'OK'}, `Saved!`);
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: this.race.name, disabled: true },
        { text: 'Certificate', disabled: true },
      ];
    },

    editorEmbedUrl() {
      return `https://studio.pixelixe.com/#api?apiKey=${process.env.VUE_APP_PIXELIXE_PUBLIC_KEY}&width=500&height=500&webhook_url=${process.env.VUE_APP_PIXELIXE_WEBHOOK_URL}&user_uid=${this.event.id}&custom_field_1=${this.event.id}&custom_field_2=${this.race.id}&document_uid=${this.race.certificate_doc_id||''}`;
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

